import React, { useEffect, useState } from "react";
import {
  useAddExtraSelector,
  useAddFeaturedExtraSelector,
  useBookingSearchDateRange,
  useBookingSearchDda,
  useBookingSearchGuests,
  useBookingSearchSelectedLocationIds,
  useBookingSummaryStateSelector,
  useCreatePaymentStateSelector,
  useFindStepSelector,
} from "@/store/selectors";
import { FHTimerModal } from "@components/SemanticTheme/FHTimerModal";
import { generateBookingQueryString } from "src/utils/common";
import { useRouter } from "next/router";
import { StepperTag } from "src/interfaces/booking";
import { useDispatch } from "react-redux";
import {
  addExtraResetState,
  addFeaturedExtraResetState,
  createCardPaymentReset,
  resetBookingSummary,
} from "@/store/actions";

const BookingExpiry: React.FC = (): JSX.Element => {
  const history = useRouter();
  const dispatch = useDispatch();
  const dateRange = useBookingSearchDateRange();
  const guests = useBookingSearchGuests();
  const dda = useBookingSearchDda();
  const locationId = useBookingSearchSelectedLocationIds();
  const cabinStep = useFindStepSelector(StepperTag.cabins);
  const { cabin, errorMessage } = useBookingSummaryStateSelector();
  const { errorMessage: paymentError } = useCreatePaymentStateSelector();
  const { errorMessage: addExtraErrorMessage } = useAddExtraSelector();
  const { errorMessage: addExtrasErrorMessage } = useAddFeaturedExtraSelector();

  const [bookingExpired, setBookingExpired] = useState(false);

  const searchDeatils = generateBookingQueryString({
    startDate: dateRange.startDateISO || "",
    endDate: dateRange.endDateISO || "",
    locationIds: locationId,
    guests: guests,
    dda: dda,
  });

  useEffect(() => {
    const isBookingExpired =
      cabin?.reservationProcessStage === "QUOTEDECLINED" ||
      cabin?.reservationProcessStage === "CANCELLED" ||
      (errorMessage?.includes("A booking with identifier") &&
        errorMessage?.includes("could not be found.")) ||
      paymentError === "BookingId does not match a valid booking" ||
      addExtraErrorMessage === "Reservation was cancelled" ||
      addExtrasErrorMessage === "Reservation was cancelled";
    if (isBookingExpired) setBookingExpired(true);
  }, [
    cabin,
    errorMessage,
    paymentError,
    addExtraErrorMessage,
    addExtrasErrorMessage,
  ]);

  return (
    <>
      {bookingExpired && (
        <FHTimerModal
          open={bookingExpired}
          messageIcon={require("../../../components/assests/ic_timer.png")}
          iconHeight={50}
          iconWidth={43}
          handleClose={(_, reason) => {
            if (reason && reason === "backdropClick") return;
            setBookingExpired(false);

            if (locationId?.length > 0)
              history.push({
                pathname: cabinStep?.url,
                search: searchDeatils,
              });

            dispatch(addExtraResetState({}));
            dispatch(addFeaturedExtraResetState());
            dispatch(resetBookingSummary());
            dispatch(createCardPaymentReset());
          }}
          title="Your booking has expired"
          confirmCTALabel="Return to search"
          isStaticImg
        />
      )}
    </>
  );
};

export default BookingExpiry;
